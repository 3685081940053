<template>
  <div class="module-container">
    <div class="module-top-nva">
      <template v-for="(btn, idx) in btns">
        <a href="javascript:void(0);" v-if="canOp(btn.opCode)"  :key="idx" :class="['module-nva-but', curBtn == btn.code ? 'active' : '']" @click.stop="switchMenu(btn.code)">
          <img :src="curBtn == btn.code ? btn.imgActive:btn.img" alt="">{{showLang(btn.lang)}}</a>
      </template>
    </div>
    <div class="module-content">
      <template v-for="(item, idx) in btns">
        <component :ref="`lux_item_${item.code}`" v-if="item.code == curBtn  && canOp(item.opCode)" :key="idx" v-bind:is="item.com" :args="item.args" @doAction="doAction"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState,mapGetters} from 'vuex'
// import CommonTree from '../tree/Index'
import OnekeyLamp from './OnekeyLamp/Index'
// import LuxBase from './base/Index'
import LuxBase from './state/Index'
import LuxConfig from './config/Index'
import LuxSearch from './search/Index'
export default {
  name: 'LuxHome',
  components: {
    // CommonTree,
  },
  data() {
    return {
      curBtn: 's0',
      btns: [
        { code: 's0', name: '一键开关灯', lang:'lux.one.lights',com: OnekeyLamp, img: this.img.lux.nav.lamp, imgActive: this.img.lux.nav.lamp2, args: { stationId: 0, start: 0, end: 3 }, opCode: ["ocv", 'occ'] },
        { code: 's1', name: '光照度看板', lang:'lux.board',com: LuxBase, img: this.img.lux.nav.bulletin, imgActive: this.img.lux.nav.bulletin2, args: { stationId: 0, start: 0, end: 3 }, opCode: ["lvc", 'lvv'] },
        { code: 's2', name: '数据查询', lang:'lux.data.query',com: LuxSearch, img: this.img.lux.nav.data, imgActive: this.img.lux.nav.data2, args: { stationId: 0, start: 0, end: 3 }, opCode: [] },
        { code: 's4', name: '设备配置', lang:'lux.device.config',com: LuxConfig, img: this.img.lux.nav.setUp, imgActive: this.img.lux.nav.setUp2, args: { stationId: 0, start: 0, end: 3 }, opCode: [] },
      ]
    }
  },
  watch: {
  },
  mounted: function () {
    this.showAskModals();
  },
  destroyed: function () {
  },
  computed: {
    ...mapState('auth', ['user', 'ops']),
    ...mapGetters('auth', ['showLang','funCodes']),
    ...mapState('group', ['selectedNode', 'groupLoaded']),
    ...mapState('common', ['weeks', 'productCodes']),
  },
  methods: {
    canOp(opCode) {
      if (this.user && this.user.isSuper) return true;
      if(opCode.length==0) return true;
      for (let index = 0; index < opCode.length; index++) {
        if (this.ops && this.ops.indexOf(opCode[index]) != -1) {
          return true;
        }
      }
      return false;
    },
    showAskModals: function () {
      this.$store.dispatch('cmd/queryWaitCmds')
    },
    doAction: function (params) {
      if (params.cmd == 'gotoSearch') {
        this.curBtn = 's2';
        setTimeout(() => {
          if (this.$refs.lux_item_s2 && this.$refs.lux_item_s2.length > 0) {
            this.$refs.lux_item_s2[0].setStationId(params.stationId);
          }
        }, 300);
      }
    },
    switchMenu: function (menu) {
      this.curBtn = menu;
    },
  }
}
</script>
<style scoped>
.module-container {
  background: #e3e9ef;
  display: flex;
  width: 100%;
  align-items: stretch;
  position: relative;
  flex-direction: column;
}
.tree-container {
  /* border: solid 1px red; */
  width: 300px;
  margin: 0;
  flex: none;
  /* background-color: #001F37; */
  /* display: flex;
  flex-direction: column; */
}
.module-content {
  flex: auto;
}
.float-btns {
  position: absolute;
  top: 160px;
  right: 0;
  width: 37px;
  height: 400px;
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 200;
  background: #ffffff;
  box-shadow: 0px 4px 40px 0px rgba(56, 128, 252, 0.5);
  border-radius: 12px 0px 0px 12px;
  padding: 10px;
}
.btn-item {
  width: 36px;
  height: 102px;
  cursor: pointer;
}
.box-button-line {
  width: 20px;
  height: 1px;
  flex: none;
  background: #c0c5cb;
}
.box-button-item {
  flex: none;
  width: 20px;
  padding: 10px;
  color: #324252;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.box-button-item img {
  width: 14px;
  height: 14px;
}
.box-button-item span {
  width: 18px;
  margin: 5px 0 0 6px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 400;
}
.box-button-active {
  color: #3880fc;
}
.module-top-nva {
  width: 100%;
  height: 70px;
  background: #ffffff;
  margin: 16px 0;
}
.module-nva-but {
  display: inline-block;
  height: 70px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  line-height: 70px;
  text-align: center;
  margin: 0 20px 0 20px;
}
.module-nva-but.active {
  color: #3880fc;
}
.module-nva-but.active::after {
  content: "";
  width: calc(100%);
  height: 1px;
  display: block;
  margin: -4px auto 0;
  border-bottom: 4px solid #3880fc;
  border-radius: 2px;
}
.module-nva-but img {
  vertical-align: middle;
  margin-right: 5px;
}
</style>
<template>
  <div class="search-data-container">
    <div class="search-data-header">
      <div class="search-data-query">
        {{showLang('com.ins.last.id')}}：
        <Input type="text" v-model="form.code" clearable style="width: 150px; margin: 0 10px;"></Input>
        {{showLang('com.ins.name')}}：
        <Input type="text" v-model="form.name" clearable style="width: 150px; margin: 0 10px;"></Input>
        {{showLang('com.date.start')}}：
        <DatePicker v-model="form.start" type="datetime" style="width: 175px; margin-right: 10px;"></DatePicker>
        {{showLang('com.date.end')}}：
        <DatePicker v-model="form.end" type="datetime" style="width: 175px; margin-right: 10px;"></DatePicker>
        <Button v-if="funCodes('sccv1')" type="info" :loading="loading" @click="search" style="margin-right: 10px;">{{showLang('com.op.query')}}</Button>
      </div>
    </div>
    <div class="search-data-content" ref="table1">
      <vxe-table :empty-text="showLang('com.data.no')" :loading="loading" border resizable show-overflow
        show-header-overflow keep-source ref="chkTable" :data="tabData" :height="tabHeight"
        :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',}"
        footer-cell-class-name="footer-cell-class">
        <vxe-column type="seq" width="60"></vxe-column>
        <vxe-column field="stationName" :title="showLang('com.site.affiliated')" width="150" header-align="center">
          <template #default="{ row }">
            {{row.stationName}}
          </template>
        </vxe-column>
        <vxe-column field="paramName" :title="showLang('com.tab.title')" width="150" header-align="center">
          <template #default="{ row }">
            {{row.paramName}}
          </template>
        </vxe-column>
        <vxe-column field="code" :title="showLang('com.export.cn.connid')" width="150" header-align="center">
          <template #default="{ row }">
            {{row.code}}
          </template>
        </vxe-column>
        <vxe-column field="createTime" :title="showLang('com.date.Creation')" width="150" header-align="center">
          <template #default="{ row }">
            {{row.createTime}}
          </template>
        </vxe-column>
        <vxe-column field="sendTime" :title="showLang('com.lighting.sending.time')" width="150" header-align="center">
          <template #default="{ row }">
            {{row.sendTime==''?'-':row.sendTime}}
          </template>
        </vxe-column>
        <vxe-column field="commandName" :title="showLang('com.ins.name')" width="120" header-align="center">
          <template #default="{ row }">
            {{row.commandName}}
          </template>
        </vxe-column>
        <vxe-column field="sourceName" :title="showLang('com.ins.source')" width="80" header-align="center">
          <template #default="{ row }">
            {{row.sourceName}}
          </template>
        </vxe-column>
        <vxe-column field="sendUserName" :title="showLang('com.user.send')" width="80" header-align="center">
          <template #default="{ row }">
            {{row.sendUserName}}
          </template>
        </vxe-column>
        <vxe-column field="checkUserName" :title="showLang('com.user.check')" width="80" header-align="center">
          <template #default="{ row }">
            {{row.checkUserName}}
          </template>
        </vxe-column>
        <vxe-column field="args" :title="showLang('com.parameter')" width="120" header-align="center">
          <template #default="{ row }">
            {{getCmdArgs(row)}}
          </template>
        </vxe-column>
        <vxe-column field="replyTime" :title="showLang('com.date.recovery')" width="150" header-align="center">
          <template #default="{ row }">
            {{row.status > 6 ? row.replyTime : '-'}}
          </template>
        </vxe-column>
        <vxe-column field="message" :title="showLang('com.ins.result')" header-align="center">
          <template #default="{ row }">
            {{getCmdResult(row)}}
          </template>
        </vxe-column>
      </vxe-table>
      <vxe-pager :loading="loading" :current-page="form.index" :page-size="form.size" :total="count" :page-sizes='pageSizes'
        :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
        @page-change="handlePageChange1">
      </vxe-pager>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'StationConfigIndex',
  components: {
  },
  props: {
    sources: {
      type: Array,
      default() { return []; }
    }
  },
  data() {
    return {
      loading: false,
      tabHeight: 1,
      tabData: [],
      exportLoading: false,
      pageSizes:[20,100,500,1000,5000],
      count: 0,
      form: {
        groupId: 0,
        stationId: 0,
        code: '',
        name: '',
        source: 3,
        start: "",
        end: "",
        index: 1,
        size: 20,
      },
      history: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    selectedNode() {
      this.search();
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    let now = new Date();
    now.setHours(now.getHours() + 1);
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setHours(now.getHours() - 3);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.search();
  },
  destroyed: function () {
  },
  methods: {
    search: function(){
      this.form.index = 1;
      this.getList();
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 50;
    },
    handlePageChange1: function (params) {
      this.form.index = params.currentPage
      this.form.size = params.pageSize
      this.getList()
    },
    getList() {
      let groupId = 0, stationId = 0;
      if(this.selectedNode.type == this.productCodes.station){
        stationId = this.selectedNode.id;
      }else{
        groupId = this.selectedNode.id;
      }
      this.form.groupId = groupId;
      this.form.stationId = stationId;
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/command/QueryGatewayOld`, this.form).then(res => {
        this.loading = false;
        if (res.code == 0) {
          this.$set(this, 'tabData', res.data.list);
          this.count = res.data.count;
        }
      });
    },
    getCmdArgs: function(cmd){
      switch(cmd.command){
        default: return '-';
      }
    },
    getCmdResult: function(cmd){
      if(cmd.status == 0)return '未执行';
      if(cmd.status != 9){
        return cmd.result.message;
      }
      switch(cmd.command){
        default: return '执行成功';
      }
    },
  }
}
</script>
<style scoped>
.search-data-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header {
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content {
  height: 100px;
  flex: auto;
  /* border: solid 1px blue; */
}
.search-data-query {
  width: 830px;
  flex: auto;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view {
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
</style>